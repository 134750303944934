import React, { Component } from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet";

import ReactHtmlParser from 'react-html-parser';


import Swiper, { EffectCoverflow, Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';


import PopupForm from "../components/popupForm";
import PopupVideo from "../components/popupVideo";

import { graphql } from "gatsby";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';


import "../css/swiper-bundle.min.css";

Swiper.use([EffectCoverflow, Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);

class Scholership extends Component {

	constructor(props) {
		super(props);
		this.state = {
			time: 90000000,
		};
	}

	handlePopupWindow = () => {
		this.setState({ time: Math.random() });
	};


	homeOurUniPartLink = (e) => {
		const utabHead = e.target.parentNode;

		if (utabHead) {
			const universityPartnersSec = document.querySelector('.home-university-partners-sec');
			if (utabHead.classList.contains('active')) {
				utabHead.classList.remove('active');
				universityPartnersSec.classList.remove('active');
				document.querySelector('.view-more-universities-btn').textContent = document.querySelector('.view-more-universities-btn').textContent.replace('Less', 'More').replace('-', '+');

			} else {
				utabHead.classList.add('active');
				universityPartnersSec.classList.add('active');
				document.querySelector('.view-more-universities-btn').textContent = document.querySelector('.view-more-universities-btn').textContent.replace('More', 'Less').replace('+', '-');;

			}
		}

	};

	handleClick(section) {

		const element = document.getElementById(section);


		const offset = 240;
		const elementPosition = element.getBoundingClientRect().top + window.scrollY;

	
		const isMobile = window.innerWidth <= 768; 

		if (isMobile) {
	
			const mobileSection = section + '-mobile';
			const mobileElement = document.getElementById(mobileSection);
		
			mobileElement.innerHTML = element.querySelector('.scholarship-content').innerHTML;
			mobileElement.classList.toggle('active');


			
			const activeElementsMobile = document.querySelectorAll('.active:not(#' + section + '-mobile)');

			activeElementsMobile.forEach(activeElementsMobile => {
				activeElementsMobile.classList.remove('active');
			});


		} else {
			element.classList.toggle('active');

			
			const activeElements = document.querySelectorAll('.active:not(#' + section + ')');

			
			activeElements.forEach(activeElement => {
				activeElement.classList.remove('active');
			});

			const offset = 240;
			const elementPosition = element.getBoundingClientRect().top + window.scrollY;
			window.scrollTo({
				top: elementPosition - offset,
				behavior: 'smooth',
			});
		}
	}

	handleUniversityClick(section) {
		
		var elements = document.getElementsByClassName('scholarship-uni');

		
		for (var i = 0; i < elements.length; i++) {
			elements[i].classList.remove('active');
		}

		const element = document.getElementById(section);
		element.classList.toggle('active');

		
		const otherSections = ['australia', 'uk', 'usa'].filter(
			(section) => section !== section
		);

		otherSections.forEach((otherSection) => {
			const otherElement = document.getElementById(otherSection);
			if (otherElement) {
				otherElement.classList.remove('active');
			}
		});
	}
	componentDidMount() {
		document.getElementById('speak-to-an-expert').onclick = function () {
			document.getElementById('book-an-appointment').click();
		}
		var getInTouchLinks = document.querySelectorAll('a[href="#get-in-touch"]');


		getInTouchLinks.forEach(function(link) {
			
		  link.addEventListener('click', function(event) {
			event.preventDefault();
			
			document.getElementById('book-an-appointment').click();
		  });
		});

		let swiperSlides;
		if (window.innerWidth > 768) {
			swiperSlides = document.querySelectorAll('.home-testimonials-box-new .swiper-slide.mobile');
		} else {
			swiperSlides = document.querySelectorAll('.home-testimonials-box-new .swiper-slide.desktop');
		}

		var swiper = new Swiper('.lp-testimonials-box', {
			      centeredSlides: false,
			      loop: false,
			      slidesPerView: 1,
			      spaceBetween: 0,
			      slidesPerGroup: 1,
			      autoplay: {
			        delay: 7000,
			        disableOnInteraction: false
			      },
			      pagination: {
			        el: '.swiper-pagination-testimonial',
			        clickable: true,
			      },
			      navigation: {
			        nextEl: '.swiper-next-testimonial',
			        prevEl: '.swiper-prev-testimonial',
			      },
			      
			      breakpoints: {
			        1200: { slidesPerGroup: 3, slidesPerView: 3, spaceBetween: 20},
			        991: { slidesPerGroup: 2, slidesPerView: 2},
			        768: { slidesPerGroup: 1, slidesPerView: 1 }
			      }
			    });
	



	}
	render() {

		const post = this.props.data.allWordpressPage.nodes[0];
		const metatitle = post.acf.meta_title;
		const metadescription = post.acf.meta_description;
		const focus_keyphrase = post.acf.focus_keyphrase;
		const canonical_url =`https://theworldgrad.com${this.props.path}`;

		var headerBackground = "/static/americanbanner-31161936b50af35a68b688be75f818f4.png";
		if (post.featured_media) {
			headerBackground = post.featured_media.source_url;
		}
		return (
			<div className="about-page">
				<PopupForm time={this.state.time} />
				<Layout>
					<Helmet>
						<title>{metatitle}</title>
						<meta name="description" content={metadescription} />
						{(() => { if (post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) { return (<meta name="keywords" content={focus_keyphrase} />) } })()}
						<link rel="canonical" href={canonical_url} /> 
						{(() => { if (post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) { return (<script type="application/ld+json">{JSON.stringify(JSON.parse(post.acf.dynamic_schema))}</script>) } })()}
						
					</Helmet>


					<div class="newStyle font-poppin scholarships">
						<div className="container">
							<ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
								<li class="breadcrumb-item">
									<a href="/">Home </a>
								</li>
								<li class="breadcrumb-item active">{post.title}</li>
							</ol>
						</div>
						<div id="scholarship-band" class="top-bar-style-two top-message font-weight-500 text-center text-white"><span>{post.acf.banner_section.text_1} {post.acf.banner_section.text_2}</span></div>
						<section class="wow animate__fadeIn bg-light-gray padding-120px-tb sm-padding-25px-top sm-padding-30px-bottom  position-relative scholar-banner bg-cover480br " style={{ backgroundImage: `url(${post.acf.banner_section.background_image?.source_url})` }}
						>

							<div class="container">
								<div class="row">
									<div class="col-7 col-xl-9 col-sm-9 col-md-9 position-relative page-title-large ">

										<h2 class="yellow-background font-weight-500 alt-font  letter-spacing-1px text-black text-uppercase margin-20px-bottom d-none-991">{post.acf.banner_section.text_1}</h2>

										<h1 class="text-white alt-font font-weight-500 letter-spacing-1px line-height-50 sm-line-height-45 xs-line-height-30  margin-15px-bottom text-uppercase d-none-991">{post.acf.banner_section.text_2}</h1>

										<h2 class="alt-font font-weight-300 text-white margin-30px-bottom sm-margin-10px-bottom small-h2 " >{post.acf.banner_section.text_3}<br class="d-show-991" /> <span>{post.acf.banner_section.inr_text} </span><CountUp start={1} end={post.acf.banner_section.animated_number} redraw={true} separator="," duration={1.5} formatter={(value) => value.toLocaleString()} delay={1.25}>
											{({ countUpRef, start }) => (
												<VisibilitySensor onChange={start} delayedCall>
													<span ref={countUpRef}></span>
												</VisibilitySensor>
											)}
										</CountUp><span> {post.acf.banner_section.crore_text}</span></h2>


										<a data-animation="animated zoomInUp" class="btn btn-theme effect btn-md modal-popup " id="speak-to-an-expert" href={post.acf.banner_section.button_link}>{post.acf.banner_section.button_text}</a>


									</div>
								</div>
							</div>
							<p class="text-white limited-slot"><br />{post.acf.banner_section.small_text}</p>
						</section>
						<section class="half-section">
							<div class="container">
								<div class="row justify-content-center">
									<div class="col-12 col-xl-6 col-lg-6 col-md-10 md-margin-one-bottom ">
										<div class="col-12 p-0  wow animate__slideInLeft" >
											<div class="w-90 lg-w-80 sm-w-100 text-justify " >
												<div class="no-margin-bottom program-content" />
												
												<h2>{post.acf.scholarship_intro_section.intro_title}</h2>
												<p>{ReactHtmlParser(post.acf.scholarship_intro_section.intro_content)}</p>

												<a data-animation="animated zoomInUp" class="btn btn-theme effect btn-md center-button-110 modal-popup speak-to-an-expert left-60p d-none-991" href={post.acf.scholarship_intro_section.button_link}>{post.acf.scholarship_intro_section.button_text}</a>
											</div>

										</div>

									</div>


									<div class="animate__slideInRight animated bg-cover sm-margin-10px-top sm-margin-40px-bottom br-15 col-12 col-lg-6 margin15  mt-lg-0  mx-0 p-0 position-relative wow" data-wow-delay="0.1s"  style={{ backgroundImage: `url(${post.acf.scholarship_intro_section.intro_image?.source_url})` }}   >
										<img src={post.acf.scholarship_intro_section.intro_image?.source_url} alt="Student Visa" data-no-retina="" class="br-15 margin15 opacity-0 " />
										<div class="opacity-very-light bg-medium-slate-blue br-15 "></div>


									</div>
									<div class="col-12 col-xl-6 col-lg-6 col-md-10 margin-30px-bottom d-show-991">
										<a data-animation="animated zoomInUp" class="btn btn-theme effect btn-md center-button-110 modal-popup speak-to-an-expert left-60p" href={post.acf.scholarship_intro_section.button_link}>{post.acf.scholarship_intro_section.button_text}</a>
									</div>
								</div>
							</div>
						</section>

						<section id="about" class="half-section bg-light-gray wow animate__fadeIn">
							<div class="container">
								<div class="row margin-10px-bottom">
									<div class="col-12 text-center col-lg-12 col-sm-12">
										<h2>{post.acf.our_scholarships_section.our_scholarships_title}</h2>


									</div>
								</div>

								<div class="row align-items-center justify-content-center margin-30px-bottom">



									<div class="col-12 col-lg-4 col-md-6 sm-margin-30px-bottom wow animate__fadeIn" data-wow-delay="0.2s">
										<div class="services-box-style-01 bg-extra-dark-gray box-shadow-large border-radius-10px overflow-hidden">
											<div class="imagex-box position-relative overflow-hidden">
												<img src={post.acf.our_scholarships_section.our_scholarships_boxes[0].image?.source_url} alt="" data-no-retina="" />

											</div>
											<div class="position-relative bg-white padding-1-half-rem-tb padding-1-half-rem-lr">

												<span class="alt-font font-weight-500 text-dark-charcoal text-extra-large d-block margin-5px-bottom">{post.acf.our_scholarships_section.our_scholarships_boxes[0].title}</span>

												<div class="position-relative"><a onClick={() => this.handleClick('smartgrad-scholarship')} class="btn btn-theme effect btn-sm uni-read-more-plus-btn"><span><img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMjU2IDgwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjIyNEg0OGMtMTcuNyAwLTMyIDE0LjMtMzIgMzJzMTQuMyAzMiAzMiAzMkgxOTJWNDMyYzAgMTcuNyAxNC4zIDMyIDMyIDMyczMyLTE0LjMgMzItMzJWMjg4SDQwMGMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkgyNTZWODB6Ii8+PC9zdmc+" alt="+" class="plusicon" /></span></a></div>

												<div class="twg-scholarship-mobile" id="smartgrad-scholarship-mobile">
													.

												</div>

												<div class="w-100 h-8px bg-orng position-absolute left-0px bottom-0px d-block"></div>
											</div>
										</div>
									</div>

									<div class="col-12 col-lg-4 col-md-6 sm-margin-30px-bottom wow animate__fadeIn" data-wow-delay="0.4s" >
										<div class="services-box-style-01 bg-extra-dark-gray  box-shadow-large border-radius-10px overflow-hidden">
											<div class="imagex-box position-relative overflow-hidden">
												<img src={post.acf.our_scholarships_section.our_scholarships_boxes[1].image?.source_url} alt="" data-no-retina="" />

											</div>
											<div class="position-relative bg-white padding-1-half-rem-tb padding-1-half-rem-lr">

												<span class="alt-font font-weight-500 text-dark-charcoal text-extra-large d-block margin-5px-bottom">{post.acf.our_scholarships_section.our_scholarships_boxes[1].title}</span>

												<div class="position-relative"><a onClick={() => this.handleClick('director-merit')} class="btn btn-theme effect btn-sm uni-read-more-plus-btn"><span><img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMjU2IDgwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjIyNEg0OGMtMTcuNyAwLTMyIDE0LjMtMzIgMzJzMTQuMyAzMiAzMiAzMkgxOTJWNDMyYzAgMTcuNyAxNC4zIDMyIDMyIDMyczMyLTE0LjMgMzItMzJWMjg4SDQwMGMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkgyNTZWODB6Ii8+PC9zdmc+" alt="+" class="plusicon" /></span></a></div>

												<div class="twg-scholarship-mobile" id="director-merit-mobile">
													.

												</div>

												<div class="w-100 h-8px bg-fast-blue position-absolute left-0px bottom-0px d-block"></div>
											</div>
										</div>
									</div>

									<div class="col-12 col-lg-4 col-md-6 sm-margin-10px-bottom wow animate__fadeIn" data-wow-delay="0.6s">
										<div class="services-box-style-01 bg-extra-dark-gray box-shadow-large border-radius-10px overflow-hidden">
											<div class="imagex-box position-relative overflow-hidden">
												<img src={post.acf.our_scholarships_section.our_scholarships_boxes[2].image?.source_url} alt="" data-no-retina="" />

											</div>
											<div class="position-relative bg-white last-paragraph-no-margin padding-1-half-rem-tb padding-1-half-rem-lr">

												<span class="alt-font font-weight-500 text-dark-charcoal text-extra-large d-block margin-5px-bottom">{post.acf.our_scholarships_section.our_scholarships_boxes[2].title}</span>


												<div class="position-relative"><a onClick={() => this.handleClick('overseas-sch')} class="btn btn-theme effect btn-sm uni-read-more-plus-btn"><span><img src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0NDggNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuNC4yIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIzIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBkPSJNMjU2IDgwYzAtMTcuNy0xNC4zLTMyLTMyLTMycy0zMiAxNC4zLTMyIDMyVjIyNEg0OGMtMTcuNyAwLTMyIDE0LjMtMzIgMzJzMTQuMyAzMiAzMiAzMkgxOTJWNDMyYzAgMTcuNyAxNC4zIDMyIDMyIDMyczMyLTE0LjMgMzItMzJWMjg4SDQwMGMxNy43IDAgMzItMTQuMyAzMi0zMnMtMTQuMy0zMi0zMi0zMkgyNTZWODB6Ii8+PC9zdmc+" alt="+" class="plusicon" /></span></a></div>

												<div class="twg-scholarship-mobile" id="overseas-sch-mobile">
													.

												</div>

												<div class="w-100 h-8px bg-yellow position-absolute left-0px bottom-0px d-block"></div>
											</div>
										</div>
									</div>

								</div>
								<div class="row margin-30px-top twg-scholarship" id="smartgrad-scholarship">
									<div class="col-12 col-lg-12 col-sm-12 ">
										<div class="box-shadow-large padding-2-rem-all overflow-hidden  border-radius-8px scholarship-content">
											<h2 class="d-none-991">{post.acf.our_scholarships_section.our_scholarships_boxes[0].title}</h2>

											{ReactHtmlParser(post.acf.our_scholarships_section.our_scholarships_boxes[0].content)}
										</div>
									</div>
								</div>

								<div class="row  margin-30px-top  twg-scholarship" id="director-merit">
									<div class="col-12 col-lg-12 col-sm-12">
										<div class="box-shadow-large padding-2-rem-all overflow-hidden  border-radius-8px  scholarship-content">

											<h2 class="d-none-991">{post.acf.our_scholarships_section.our_scholarships_boxes[1].title}</h2>

											{ReactHtmlParser(post.acf.our_scholarships_section.our_scholarships_boxes[1].content)}
										</div>
									</div>
								</div>

								<div class="row  margin-30px-top  twg-scholarship" id="overseas-sch">
									<div class="col-12 col-lg-12 col-sm-12">
										<div class="box-shadow-large padding-2-rem-all overflow-hidden  border-radius-8px  scholarship-content">

											<h2 class="d-none-991">{post.acf.our_scholarships_section.our_scholarships_boxes[2].title}</h2>
											{ReactHtmlParser(post.acf.our_scholarships_section.our_scholarships_boxes[2].content)}


										</div>
									</div>
								</div>
							</div>
						</section>

						<section class="half-section bg-white home-university-partners-sec">
							<div class="container">
								<div class="row ">
									<div class="col-12 text-center col-lg-12 col-sm-12 margin-15px-bottom">
										<h2>{post.acf.university_scholarship_section.university_scholarship_title}</h2>


									</div>
								</div>
								<div class="home-university">
								<div class="row">
  {post.acf.university_scholarship_section.university_scholarship_boxes.slice(0, 6).map((university, index) => (
    <div key={index} className="col-md-3 col-lg-2 col-6 margin-15px-bottom">
      <a href={university.link} target="_blank" className="uniPartnerLogo ">
		<div class="uni-image-box">
        <img src={university.logo?.source_url} alt={university.university_name} />
		</div>
		<div class="uni-image-box">
        <span className="w-100">{university.university_name}</span>
    
		</div>
		<img src={university.flag?.source_url} alt="Flag" className="uni-flag" />
      </a>
    </div>
  ))}
</div>

<div class="row remaining-universities home-university-partners-detail ">
  {post.acf.university_scholarship_section.university_scholarship_boxes.slice(6).map((university, index) => (
    <div key={index} className="col-md-3 col-lg-2 col-6 margin-15px-bottom">
      <a href={university.link} target="_blank" className="uniPartnerLogo">
	  <div class="uni-image-box">
        <img src={university.logo?.source_url} alt={university.university_name} />
		</div>
		<div class="uni-image-box">
        <span className="w-100">{university.university_name}</span>
    
		</div>
		<img src={university.flag?.source_url} alt="Flag" className="uni-flag" />
      </a>
    </div>
  ))}
</div>

<div class="col-12 text-center mt-4">
  <a onClick={(e) => this.homeOurUniPartLink(e)} class=" view-more-universities-btn">See More +</a>
</div>

								
							</div>
							</div>
						</section>



						<section class="half-section bg-light-gray wow animate__fadeIn">
							<div class="container">
								<div class="row justify-content-center">

									<div class="col-md-12 text-center margin-two-bottom"><h2>{post.acf.our_scholarship_winners_section.our_scholarship_winners_title}</h2></div>
									</div>

									<div class="row">
                  <div class="col-md-12">
                    <div class="swiper-container swiper-style-new-design lp-testimonials-box home-testimonials-box-new">
                      <div className="swiper-wrapper">

									{post.acf.our_scholarship_winners_section.testimonials.map((testmonial) => (
                          <div class="swiper-slide desktop">
                            <div class="lp-testimonial-box w-100 h-100">
                              <div class="lp-scholership"> 
                              {testmonial.scholership_image && (
  <img
    src={testmonial.scholership_image.source_url}
    alt={testmonial.candidate_name}
    className="lp-testimonial-scholership-img"
  />
)}
                              </div>
							  
                              <div class="lp-testimonial-head">
                                
                                    
                                <img src={testmonial.testimonial_image.source_url} alt={testmonial.title} class="lp-testimonial-head-img" /><br/>
           
                                <div class="lp-testimonial-head-detail">
                                  <p class="mb-0 font-weight-500">{testmonial.candidate_name}</p><p class="font-weight-500 mb-2">{testmonial.location}</p>
                                  <p class="mb-0">{testmonial.batch_detail}</p>
                                  <p class="mb-0">{testmonial.program_name}</p>
                                </div>
                               
                              </div>

                              <img src={testmonial.country_flag?.source_url} alt="Flag" class="lp-testimonial-qoute" />
                              <div class="lp-testimonial-detail">
                              
                                {ReactHtmlParser(testmonial.testimonial_content)}
                                
                              </div>
                              
                              {testmonial.youtube_video_link && testmonial.youtube_video_link !== '#' && (
                                  <div class="lp-testimonial-date">
                                    <PopupVideo time={this.state.time} videoURL={testmonial.youtube_video_link} designType="New" />
                                    
                                  </div>
                                )}
                            </div>
                          </div>
                        ))}


                    </div>
					<div className="swiper-pagination swiper-pagination-testimonial"></div>
                  </div>
				  
                </div>
              </div>

								</div>
							
						</section>
				
						<section class="half-section  wow animate__fadeIn">
							<div class="container">
								<div class="row">
									<div class="col-md-10 offset-md-1 text-center ">
										<h2>{post.acf.contact_us_section.contact_us_title}</h2>
									</div>
								</div>
								<div class="row justify-content-center">
									<div class="col-12 col-lg-10 wow animate__fadeIn text-center">
										<p classname="american-dream-card-text ">{ReactHtmlParser(post.acf.contact_us_section.content)}
										</p>
										
										<a data-animation="animated zoomInUp" class="btn btn-theme effect btn-md " href={post.acf.contact_us_section.button_link}>{post.acf.contact_us_section.button_text}</a>
									</div>


								</div>
							</div>
						</section>

					</div>

				</Layout>
			</div>
		);
	}
}
export default Scholership;
export const ScholershipPage = graphql`
    {
		allWordpressPage(filter: {wordpress_id: {in: 16908}}) {
			nodes {
			  title
			  wordpress_id
			  acf {
				meta_title
				meta_description
				focus_keyphrase
				canonical_url
				dynamic_schema
				scholarship_intro_section {
					intro_title
					intro_content
					button_text
					button_link
					intro_image{
		  source_url
					}
				  }
				university_scholarship_section {
				  university_scholarship_title
				  university_scholarship_boxes {
					university_name
					link
					logo {
					  source_url
					}
					flag {
					  source_url
					}
				  }
				}
				our_scholarship_winners_section {
				  our_scholarship_winners_title
				  testimonials {
					batch_detail
					candidate_name
					location
					program_name
					testimonial_content
					youtube_video_link
					testimonial_image{
						source_url
									}
					country_flag {
					  source_url
					}
					scholership_image {
					  source_url
					}
				  }
				}
				contact_us_section {
				  button_link
				  button_text
				  contact_us_title
				  content
				}
				our_scholarships_section {
					our_scholarships_title
					our_scholarships_boxes {
					  content
					  title
					  image {
						source_url
					  }
					}
				  }
				banner_section {
				  text_1
				  text_2
				  text_3
				  inr_text
				  animated_number
				  crore_text
				  button_text
				  button_link
				  small_text
				  background_image {
					source_url
				  }
				}
			  }
			}
		  }  
}
`
